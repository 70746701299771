.about-section {
  text-align: center;
  color: white;
  margin-top: 5%;
  padding-top: 2%;
  padding-bottom: 2%;
  margin-left: 10%;
  margin-right: 10%;
  border-bottom: 1px solid white;
}

.blog-section {
  text-align: center;
  color: white;
  margin-top: 10%;
}

.contact-section {
  color: white;
  margin-top: 5%;
}

.portfolio-section {
  text-align: center;
  color: white;
  margin-top: 10%;
}

.navbar-brand {
  color: white !important;
  font-weight: bolder;
  border: solid white;
  border-radius: 50%;
  padding-left: 0.5%;
  padding-right: 0.5%;
}

.nav-item:hover {
  border-bottom: solid white;
}

.info-box {
  padding: 2%;
  box-sizing: border-box;
  height: 15rem;
  margin-top: 10%;
  border-radius: 10px;
  border: solid chartreuse;
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),0 17px 50px 0 rgba(0, 0, 0, 0.19);
  overflow: hidden;
}

.left-align {
  text-align: left;
}

.center-align {
  text-align: center;
}

.skills-section {
  color: white;
  text-align: center;
}

.tools-box {
  box-sizing: border-box;
  border-top: solid white;
  border-bottom: solid white;
}

.info-box ul {
  text-align: left;
  padding-right: 2%;
}

